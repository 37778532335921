<script>
import CommentsList from '@shared/components/CommentsList.vue';
import APITraining from '@app/services/API/Trainings';

export default {
  components: { CommentsList },
  head: {
    title: 'Commentaires',
  },
  static: { APITraining },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
};
</script>

<template>
  <div>
    <CommentsList
      :api="$options.static.APITraining"
      :store="$store.getters['auth/store']"
      :author="authUser"
      :training="currentTraining"
      :training-identifier="currentTraining.uuid"
      :training-items="currentTraining.items"
    />
  </div>
</template>
